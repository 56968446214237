// app/layout.jsx
'use client';
import Script from 'next/script';

import "@styles/globals.css";
import QueryProvider from "./QueryProvider";
import ReduxProvider from "./ReduxProvider";
import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import ExternalBrowser from "@components/redirect";
import TagManager from 'react-gtm-module';
import IntercomWidget from "@components/Intercom/intercom";
import { useUser } from "@hooks/Auth/useUser";
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider
import config from "@config/config";

const AdjustInitializer = dynamic(() => import("@hooks/useAdjust"), {
  ssr: false,
});

// Replace with your GTM ID
const gtmId = 'GTM-TCGVZNBP';

// Retrieve the Google Client ID from environment variables
const GOOGLE_CLIENT_ID = config.GCLIENTID;

const RootLayout = ({ children }) => {
  const [links, setLinks] = useState(null);
  let { user } = useUser();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLinks(window.location);
    }
  }, []);

  useEffect(() => {
    // Initialize Google Tag Manager
    TagManager.initialize({ gtmId });
  }, []);

  return (
    <html lang="en">
      <head>
        {/* Meta Pixel Code */}
        <Script id="fb-pixel" strategy="afterInteractive">
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '529649936697210');
          `}
        </Script>
      </head>
      <body>
      <noscript>
          <img 
            height="1" 
            width="1" 
            style={{ display: 'none' }}
            src="https://www.facebook.com/tr?id=529649936697210&ev=PageView&noscript=1" 
            alt="Facebook Pixel" 
          />
          </noscript>
        {/* GoogleOAuthProvider wraps all other providers to make OAuth context available globally */}
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <ReduxProvider>
            <QueryProvider>
              <AdjustInitializer />
              {links ? (
                <ExternalBrowser child={children} links={links}>
                  {children}
                </ExternalBrowser>
              ) : (
                children
              )}
              <noscript>
                <iframe
                  src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
                  height="0"
                  width="0"
                  style={{ display: "none", visibility: "hidden" }}
                ></iframe>
              </noscript>
              <IntercomWidget user={user} />
              <GoogleTagManager gtmId={gtmId} />
              <GoogleAnalytics gaId="G-5E07BFZDQN" />

      {/* NoScript Fallback for Non-JS Browsers */}
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=1856299481859901&ev=PageView&noscript=1"
        />
      </noscript>

            </QueryProvider>
          </ReduxProvider>
        </GoogleOAuthProvider>
      </body>
    </html>
  );
};

export default RootLayout;
