let isProduction = true; // moving to development
//ff
let stripePK = "";
let stripePKUAE = "";
let areebaLink = "";
let areebaMerchant = "";
let url = "";
let tokenURL = "";
let googleclientid = "";
let adjustToken = "";
let baseURL = "";
let seatAPIKey = "";
if (isProduction) {
  stripePK = process.env.NEXT_PUBLIC_STRIPE_PK_PRODUCTION;
  stripePKUAE = process.env.NEXT_PUBLIC_STRIPE_PK_PRODUCTION_UAE;
  areebaLink = process.env.NEXT_PUBLIC_AREEBA_DATA_COMPLETE_PRD;
  areebaMerchant = process.env.NEXT_PUBLIC_AREEBA_MERCHANT_PRD;
  url = process.env.NEXT_PUBLIC_FIREBASE_URL;
  tokenURL = process.env.NEXT_PUBLIC_TOKEN_PROD;
  googleclientid = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID_PRD;
  adjustToken = process.env.NEXT_PUBLIC_ADJUST_TOKEN;
  baseURL = process.env.NEXT_PUBLIC_WEBSITE_BASE_URL_PRD;
  seatAPIKey = process.env.NEXT_PUBLIC_SEAT_API_KEY_PROD;
} else {
  stripePK = process.env.NEXT_PUBLIC_STRIPE_PK_DEV;
  stripePKUAE = process.env.NEXT_PUBLIC_STRIPE_PK_DEV_UAE;
  areebaLink = process.env.NEXT_PUBLIC_AREEBA_DATA_COMPLETE_DEV;
  areebaMerchant = process.env.NEXT_PUBLIC_AREEBA_MERCHANT_DEV;
  url = process.env.NEXT_PUBLIC_FIREBASE_URL_DEV;
  tokenURL = process.env.NEXT_PUBLIC_TOKEN_DEV;
  googleclientid = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID_DEV;
  adjustToken = process.env.NEXT_PUBLIC_ADJUST_TOKEN;
  baseURL = process.env.NEXT_PUBLIC_WEBSITE_BASE_URL_DEV;
  baseURL = process.env.NEXT_PUBLIC_WEBSITE_BASE_URL_DEV;
  seatAPIKey = process.env.NEXT_PUBLIC_SEAT_API_KEY_DEV;
}
const config = {
  WS_BASE_URL: `${process.env.NEXT_PUBLIC_BASE_URL}`,
  FIREBASE_WS_BASE_URL: `${url}`,
  TOKEN_API_URL: `${tokenURL}`,
  STRIPE_PK: `${stripePK}`,
  GCLIENTID: `${googleclientid}`,
  STRIPE_PK_UAE: `${stripePKUAE}`,
  AREEBA_LINK: areebaLink,
  AREEBA_MERCHANT: `${areebaMerchant}`,
  DOMAIN_NAME: `${process.env.NEXT_PUBLIC_LOCAL_HOST}`,
  DEMO: false,
  FIREBASE_BASIC_AUTH_TOKEN: `${process.env.NEXT_PUBLIC_API_KEY}`,
  FIREBASE_BASIC_Seat_AUTH_TOKEN: `${seatAPIKey}`,
  ADJUST_TOKEN: `${adjustToken}`,
  BASE_URL: baseURL,
  // FIREBASE_BEARER_AUTH_TOKEN: `${localStorage.getItem("idToken")}`,
};

export default config;
